import { gql } from "@apollo/client";

export const PROFILE_INFO = gql`
  query ProfileInfo {
    info: profileInfo {
      id
      email
      phone
      street
      suburb
      postcode
    }
  }
`;

export const SESSION_DETAILS = gql`
  query Session($sessionId: ID) {
    details: session(sessionId: $sessionId) {
      id
      name
      class
      classId
      date
      time
      duration
      teacher
      assistant
      status
      roster {
        id
        name
        attended
        studentId
        studentCommentsStaff
      }
      assessments {
        id
        from {
          id
          userId
          name
        }
        text
        created
        updated
      }
    }
  }
`;

export const TEACHER_SESSIONS = gql`
  query TeacherSessions {
    sessions: teacherSessions {
      today {
        id
        class
        name
        time
        status
        date
        commentsCountStaff
        assessmentsCount
      }
      recent {
        id
        class
        name
        time
        status
        date
        commentsCountStaff
        assessmentsCount
      }
    }
  }
`;

export const STUDENT_PROFILE = gql`
  query StudentProfileDetails($studentId: ID!) {
    profile: studentProfileDetails(studentId: $studentId) {
      student {
        id
        firstName
        middleName
        lastName
        gender
        dob
        picture
        notes
      }
      customer {
        id
        email
        phone
        street
        suburb
        postcode
      }
    }
  }
`;

export const LIST_COMMENTS = gql`
  query ListComments($entity: String, $ref: String) {
    list: listComments(entity: $entity, ref: $ref) {
      id
      to
      visibility
      text
      flagged
      updated
      from {
        id
        username
        email
      }
    }
  }
`;

export const READ_COMMENT = gql`
  query Comment($id: ID) {
    comment(commentId: $id) {
      id
      to
      visibility
      text
      flagged
      updated
      from {
        id
        username
        email
      }
    }
  }
`;
