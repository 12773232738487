import { useEffect } from "react";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { TEACHER_SESSIONS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import { TodaysList } from "../library/lists/Today";
import { RecentList } from "../library/lists/Recent";
import toast from "react-hot-toast";

function Home(props) {
  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  const { loading, data, error } = useQuery(TEACHER_SESSIONS, {
    pollInterval: 50000,
  });

  useEffect(() => {
    if (loading) notifyLoading();
  }, [loading]);

  useEffect(() => {
    if (data) toast.dismiss();
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.dismiss();
      notifyError();
    }
  }, [error]);

  return (
    <UserLayout title="Home | Swastik App">
      <section className="mb-8">
        <SectionHead heading="Sessions" />
        <label className="label">
          <span className="label-text font-semibold">
            Upcoming Sessions (next 7 days including today)
          </span>
        </label>
        {data?.sessions?.today && <TodaysList sessions={data.sessions.today} />}
      </section>
      <section className="mb-8">
        <label className="label">
          <span className="label-text font-semibold">
            Recently Completed Sessions (last 7 days)
          </span>
        </label>
        {data?.sessions?.recent && (
          <RecentList sessions={data.sessions.recent} />
        )}
      </section>
    </UserLayout>
  );
}

export default Home;
