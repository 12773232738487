import { useState, useEffect } from "react";
import { UPDATE_ROSTER } from "../../utils/mutations";
import { SESSION_DETAILS } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { StudentDrawer } from "../drawer/Student";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/20/solid";

export const RosterTable = (props) => {
  const { sessionId, roster } = props;

  const [selected, setSelected] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const notifyError = () => toast.error("Something went wrong!");
  const notifySuccess = () => toast.success("Updated successfully!");

  const [updateRosterEntry, { data, error }] = useMutation(UPDATE_ROSTER, {
    refetchQueries: [{ query: SESSION_DETAILS, variables: { sessionId } }],
  });

  const action = (id, bool) => {
    updateRosterEntry({ variables: { input: { id, attended: bool } } });
  };

  const handleOpen = (id) => {
    setOpenDrawer(true);
    setSelected(id);
  };
  const handleClose = () => {
    setOpenDrawer(false);
    setSelected(null);
  };

  useEffect(() => {
    if (data && data.success) notifySuccess();
  }, [data]);

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <div>
      <div className="overflow-x-auto rounded-xl shadow-md">
        <table className="table-normal divide-y-2 bg-white rounded-xl w-full">
          {/* head */}
          <thead className="text-left">
            <tr>
              <th></th>
              <th>Student</th>
              <th className="hidden md:block">Attendance</th>
            </tr>
          </thead>
          <tbody className="divide-y-2">
            {roster &&
              roster.map((value, index) => (
                <tr key={value.id}>
                  <th>{index + 1}</th>

                  <td className="font-bold">
                    <div className="flex flex-row gap-4 items-center">
                      <p
                        onClick={() => handleOpen(value.studentId)}
                        className="cursor-pointer underline hover:no-underline underline-offset-4 decoration-primary"
                      >
                        {value.name}
                      </p>
                      {/* comments count badge */}
                      <div className="flex flex-row items-center">
                        <div className="bg-gray-100 rounded-full px-2">
                          <p className="text-gray-600 text-sm flex items-center gap-1">
                            <ChatBubbleLeftEllipsisIcon className="h-4 w-4" />{" "}
                            <span className="font-semibold font-mono">
                              {value.studentCommentsStaff
                                ? value.studentCommentsStaff
                                : 0}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 md:hidden">
                      <button
                        className={`btn btn-xs ${
                          value.attended
                            ? "btn-disabled hover:bg-green-300 hover:text-green-900 bg-green-300 text-green-900"
                            : "btn-ghost"
                        }`}
                        onClick={() => action(value.id, true)}
                      >
                        Present
                      </button>
                      <button
                        className={`btn btn-xs ${
                          !value.attended
                            ? "btn-disabled hover:bg-red-300 hover:text-red-900 bg-red-300 text-red-900"
                            : "btn-ghost"
                        }`}
                        onClick={() => action(value.id, false)}
                      >
                        Absent
                      </button>
                    </div>
                  </td>

                  <td className="space-x-4 hidden md:block">
                    <button
                      className={`btn btn-xs ${
                        value.attended
                          ? "btn-disabled hover:bg-green-300 hover:text-green-900 bg-green-300 text-green-900"
                          : "btn-ghost"
                      }`}
                      onClick={() => action(value.id, true)}
                    >
                      Present
                    </button>
                    <button
                      className={`btn btn-xs ${
                        !value.attended
                          ? "btn-disabled hover:bg-red-300 hover:text-red-900 bg-red-300 text-red-900"
                          : "btn-ghost"
                      }`}
                      onClick={() => action(value.id, false)}
                    >
                      Absent
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {openDrawer && <StudentDrawer close={handleClose} studentId={selected} />}
    </div>
  );
};
