import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { PROFILE_INFO } from "../utils/queries";
import { useQuery } from "@apollo/client";
import { ProfileInfo } from "../library/forms/ProfileInfo";
import { ChangePassword } from "../library/forms/ChangePassword";
import toast from "react-hot-toast";
import { useEffect } from "react";

function Profile(props) {
  const notifyError = () => toast.error("Unable to get profile information!");

  const { data, error } = useQuery(PROFILE_INFO);

  useEffect(() => {
    if (error) notifyError();
  }, [error]);

  return (
    <UserLayout title="My Profile | Swastik App">
      <section>
        <SectionHead heading="My Profile" />
        {data && data.info && (
          <div className="divide-y-2 space-y-8">
            <ProfileInfo info={data.info} />
            <ChangePassword />
          </div>
        )}
      </section>
    </UserLayout>
  );
}

export default Profile;
