import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { ADD_ASSESSMENT, UPDATE_ASSESSMENT } from "../../utils/mutations";
import { SESSION_DETAILS } from "../../utils/queries";
import clsx from "clsx";
import toast from "react-hot-toast";

export const AssessmentForm = (props) => {
  const { id, type, entityId, text } = props;

  const [mode /*, setMode*/] = useState(id ? "update" : "add");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const [addAssessment, { loading: adding }] = useMutation(ADD_ASSESSMENT, {
    onCompleted: (data) => {
      if (data.created) setIsSubmitted(true);
    },
    onError: () => {
      toast.error("Unable to add assessment!");
    },
    refetchQueries: [
      {
        query: SESSION_DETAILS,
        variables: { sessionId: entityId },
      },
    ],
  });

  const [updateAssessment, { loading: updating }] = useMutation(
    UPDATE_ASSESSMENT,
    {
      onCompleted: (data) => {
        if (data.updated) setIsSubmitted(true);
      },
      onError: () => {
        toast.error("Unable to update assessment!");
      },
      refetchQueries: [
        {
          query: SESSION_DETAILS,
          variables: { sessionId: entityId },
        },
      ],
    }
  );

  const onSubmit = (data) => {
    const { assessment } = data;

    if (mode === "add") {
      addAssessment({
        variables: {
          input: {
            text: assessment.text,
            entity: type,
            ref: entityId,
          },
        },
      });
    } else {
      updateAssessment({
        variables: {
          input: {
            id: id,
            text: assessment.text,
          },
        },
      });
    }
  };

  return (
    <>
      {!isSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)} className="mb-4 space-y-2">
          {/* assessment */}
          <div className="flex flex-col gap-2">
            <textarea
              {...register("assessment.text", {
                required: true,
                minLength: 1,
                maxLength: 500,
              })}
              className="w-full border border-gray-300 rounded-md px-4 py-3"
              placeholder="Write your assessment here"
              defaultValue={text ? text : null}
              rows={6}
            />
          </div>
          <div className="flex flex-row gap-2">
            {/* submit */}
            <button
              type="submit"
              className={clsx("btn", adding || updating ? "loading" : null)}
            >
              {id ? "Update Assessment" : "Add Assessment"}
            </button>
          </div>
        </form>
      ) : (
        <div className="flex flex-col gap-2">
          <p className="font-bold text-xl mb-6">
            Assessment {mode === "add" ? "added" : "updated"} successfully!
          </p>
          <label
            htmlFor="allocate-modal"
            className="btn btn-outline"
            onClick={() => {
              setIsSubmitted(false);
              reset();
            }}
          >
            Close
          </label>
        </div>
      )}
    </>
  );
};
