import { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SessionDetails } from "../library/details/Session";
import { RosterTable } from "../library/table/Roster";
import { SESSION_DETAILS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { CommentsList } from "../library/lists/Comments";
import { AssessmentModal } from "../library/modals/Assessment";
import { AssessmentForm } from "../library/forms/Assessment";
import { AssessmentsList } from "../library/lists/Assessments";

function Session(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [addAssessment, setAddAssessment] = useState(false);

  const notifyError = () => toast.error("Unable to get details!");

  const { data } = useQuery(SESSION_DETAILS, {
    variables: { sessionId: id },
    onCompleted: (data) => {
      const { assessments } = data.details;
      const myAssessment = assessments.find(
        (assessment) => assessment.from.userId === user.data.id
      );
      setAddAssessment(!myAssessment);
    },
    onError: (error) => {
      if (error) notifyError();
    },
  });

  return (
    <UserLayout title="Session Details">
      <section>
        <SectionHead heading="Session Details">
          {addAssessment && (
            <AssessmentModal
              title="Add Assessment"
              content="Add assessment for this session"
              btn="btn btn-secondary"
              label="Add Assessment"
            >
              <AssessmentForm type="Session" entityId={id} />
            </AssessmentModal>
          )}

          <button className="btn btn-outline" onClick={() => navigate(-1)}>
            Back
          </button>
        </SectionHead>
        <div>
          {data && data.details && (
            <SessionDetails
              details={{
                name: data.details.name,
                class: data.details.class,
                classId: data.details.classId,
                date: data.details.date,
                time: data.details.time,
                teacher: data.details.teacher,
                assistant: data.details.assistant,
                duration: data.details.duration,
                status: data.details.status,
                rosterCount: data.details.roster.length,
              }}
            />
          )}
          {data && data.details && data.details.assessments.length > 0 && (
            <AssessmentsList
              sessionId={id}
              assessments={data.details.assessments}
            />
          )}
          {data && data.details && (
            <RosterTable sessionId={id} roster={data.details.roster} />
          )}
        </div>
      </section>
      <section>
        <div className="px-2 py-6">
          <h3 className="font-medium mb-4 label-text">Comments</h3>
          <CommentsList type="Class" id={id} commenting={true} />
        </div>
      </section>
    </UserLayout>
  );
}

export default Session;
