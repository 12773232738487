import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation Update($input: userProfile) {
    update(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation Change($password: String) {
    change(password: $password)
  }
`;

export const UPDATE_ROSTER = gql`
  mutation UpdateRoster($input: rosterEntry) {
    success: updateRoster(input: $input)
  }
`;

export const ADD_COMMENT = gql`
  mutation CreateComment($input: CommentDetails) {
    created: createComment(input: $input)
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($input: CommentDetails) {
    updated: updateComment(input: $input)
  }
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($id: ID) {
    removed: removeComment(commentId: $id)
  }
`;

export const ADD_ASSESSMENT = gql`
  mutation CreateAssessment($input: AssessmentDetails) {
    created: createAssessment(input: $input)
  }
`;

export const UPDATE_ASSESSMENT = gql`
  mutation UpdateAssessment($input: AssessmentDetails) {
    updated: updateAssessment(input: $input)
  }
`;

export const REMOVE_ASSESSMENT = gql`
  mutation RemoveAssessment($id: ID) {
    removed: removeAssessment(assessmentId: $id)
  }
`;

export const CLASS_NOTIFICATION = gql`
  mutation ClassNotification($input: TemplateDetails) {
    sent: classNotification(input: $input)
  }
`;
