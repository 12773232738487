import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_PROFILE } from "../../utils/mutations";
import { PROFILE_INFO } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const ProfileInfo = (props) => {
  const { info } = props;

  const [loader, setLoader] = useState(false);
  const { register, setValue, handleSubmit } = useForm();

  const notifySuccess = () => toast.success("Information updated!");
  const notifyError = () => toast.error("Unable to update information!");

  const [updateProfile, { loading, data, error }] = useMutation(
    UPDATE_PROFILE,
    {
      refetchQueries: [PROFILE_INFO],
    }
  );

  const onSubmit = (data) => {
    const { email, phone, street, suburb, postcode } = data.profile;
    updateProfile({
      variables: {
        input: {
          email: email.toLowerCase(),
          phone,
          street,
          suburb,
          postcode,
        },
      },
    });
  };

  useEffect(() => {
    if (info) setValue("profile", info);
  }, [info, setValue]);

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      notifySuccess();
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* email */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Email</span>
          </label>
          <input
            type="text"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full max-w-md bg-white ring-primary-focus"
            {...register("profile.email")}
            required
          />
        </div>
        {/* phone */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Phone</span>
          </label>
          <input
            type="text"
            placeholder="0400 000 000"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("profile.phone")}
            required
          />
        </div>
        {/* street */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Street</span>
          </label>
          <input
            type="text"
            placeholder="Street address"
            className="input input-bordered w-full max-w-md bg-white"
            {...register("profile.street")}
          />
        </div>
        {/* suburb */}
        <div className="flex space-x-3 w-full max-w-md justify-between">
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Suburb</span>
            </label>
            <input
              type="text"
              placeholder="Suburb"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("profile.suburb")}
            />
          </div>
          {/* postcode */}
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Postcode</span>
            </label>
            <input
              type="text"
              placeholder="Postcode"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("profile.postcode")}
            />
          </div>
        </div>
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};
