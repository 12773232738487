import { useState, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_COMMENTS } from "../../utils/queries";
import { UPDATE_COMMENT, REMOVE_COMMENT } from "../../utils/mutations";
import toast from "react-hot-toast";
import { convertUnixTimestamp } from "../../utils/helpers";
import { FlagIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { CommentForm } from "../forms/Comment";

export const CommentsList = (props) => {
  const { type, id, commenting = false } = props;

  const { user } = useContext(AuthContext);

  const [comments, setComments] = useState([]);
  const [primaryComments, setPrimaryComments] = useState([]);
  const [reply, setReply] = useState({});

  const secondaryComments = (id) =>
    comments.filter((comment) => comment.to === id);

  const { loading, refetch } = useQuery(LIST_COMMENTS, {
    variables: { entity: type, ref: id },
    onCompleted: (data) => {
      if (data.list.length > 0) {
        setComments(data.list);
        setPrimaryComments(data.list.filter((comment) => comment.to === null));
      } else {
        setComments([]);
        setPrimaryComments([]);
      }
    },
    onError: () => {
      toast.error("Error fetching comments");
    },
    fetchPolicy: "network-only",
  });

  const [updateComment] = useMutation(UPDATE_COMMENT, {
    onCompleted: () => {
      refetch();
    },
    onError: () => {
      toast.error("Error flagging comment");
    },
  });

  const [removeComment] = useMutation(REMOVE_COMMENT, {
    onCompleted: () => {
      refetch();
      onReset();
    },
    onError: () => {
      toast.error("Error removing comment");
    },
  });

  const toggleCommentFlag = (comment) => {
    updateComment({
      variables: {
        input: {
          id: comment.id,
          flagged: !comment.flagged,
        },
      },
    });
  };

  const onReply = (comment) => {
    if (comment) setReply({ ...comment, id: comment.id });
  };

  const onReset = () => {
    setReply({});
  };

  const onCommentDelete = (comment) => {
    window.confirm("Are you sure you want to delete this comment?") &&
      removeComment({
        variables: {
          id: comment.id,
        },
      });
  };

  const canDelete = (comment) => {
    const sameUser = comment.from.id === user.data.id;
    const adminUser = user.data.role === "ADMIN";

    return sameUser || adminUser;
  };

  if (loading) return <></>;

  return (
    <div>
      {/* form */}
      {commenting && (
        <div className="mt-4">
          <CommentForm type={type} id={id} reply={reply} reset={onReset} />
        </div>
      )}
      {/* list */}
      <ul className="divide-y">
        {primaryComments.length > 0 &&
          primaryComments.map((comment) => {
            return (
              <li key={comment.id} className="py-2">
                <div className="flex flex-row">
                  <div className="basis-auto w-full flex flex-col gap-2">
                    <h4 className="font-semibold">
                      {comment.from.username} ({comment.from?.email}):{" "}
                    </h4>
                    <p className="whitespace-pre-line">{comment.text}</p>
                    <div className="flex flex-row justify-start items-center gap-2">
                      <small>{convertUnixTimestamp(comment.updated)}</small>
                      {canDelete(comment) && (
                        <button
                          className="btn btn-ghost btn-xs"
                          onClick={() => onCommentDelete(comment)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="basis-auto flex flex-row items-start">
                    <button
                      className="btn btn-ghost btn-xs"
                      onClick={() => onReply(comment)}
                    >
                      reply
                    </button>
                    <button
                      className="btn btn-ghost btn-xs"
                      onClick={() => toggleCommentFlag(comment)}
                    >
                      <FlagIcon
                        className={clsx(
                          "h-4 w-4",
                          comment.flagged && "fill-primary"
                        )}
                      />
                    </button>
                  </div>
                </div>

                {secondaryComments(comment.id).length > 0 && (
                  <ul className="divide-y pl-8 border-t mt-2">
                    {secondaryComments(comment.id).map((comment) => {
                      return (
                        <li key={comment.id} className="py-2">
                          <div className="flex flex-row">
                            <div className="basis-auto w-full flex flex-col gap-2">
                              <h4 className="font-semibold">
                                {comment.from.username} ({comment.from?.email}):{" "}
                              </h4>
                              <p className="whitespace-pre-line">
                                {comment.text}
                              </p>
                              <div className="flex flex-row justify-start items-center gap-2">
                                <small>
                                  {convertUnixTimestamp(comment.updated)}
                                </small>
                                {canDelete(comment) && (
                                  <button
                                    className="btn btn-ghost btn-xs"
                                    onClick={() => onCommentDelete(comment)}
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        {primaryComments.length === 0 && (
          <li className="py-2">
            <p className="text-sm text-center">No comments</p>
          </li>
        )}
      </ul>
    </div>
  );
};
