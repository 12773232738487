import { Link } from "react-router-dom";
import {
  ChevronRightIcon,
  ChatBubbleLeftEllipsisIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";

export const RecentList = (props) => {
  const { sessions } = props;

  return (
    <>
      <div className="divide-y-2 bg-white rounded-2xl shadow-md">
        {sessions.length > 0 &&
          sessions.map((data, index) => (
            <div key={index}>
              <Link to={`/session/${data.id}`}>
                <div className="flex">
                  <div className="flex flex-col gap-2 md:flex-row md:justify-between py-4 pl-8 pr-3 flex-1">
                    <div className="flex flex-col space-y-2">
                      <div className="flex flex-row gap-4 items-center">
                        <h3 className="text-xl font-semibold">{data.class}</h3>
                        {/* comments count badge */}
                        <div className="flex flex-row items-center">
                          <div className="bg-gray-100 rounded-full px-2">
                            <p className="text-gray-600 text-sm flex items-center gap-1">
                              <ChatBubbleLeftEllipsisIcon className="h-4 w-4" />{" "}
                              <span className="font-semibold font-mono">
                                {data.commentsCountStaff
                                  ? data.commentsCountStaff
                                  : 0}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p>
                        <span className="font-semibold">
                          {data.name} | {data.time}
                        </span>
                      </p>
                      <p>{data.date}</p>
                    </div>
                    <div className="flex flex-row items-start md:flex-col md:items-end md:justify-center">
                      <span
                        className={`text-xs py-1 px-2 rounded-md text-white ${statusTagColor(
                          data.status
                        )}`}
                      >
                        {data.status}
                      </span>
                    </div>
                  </div>
                  <div className="flex mr-4 items-center flex-shrink-0">
                    {data.assessmentsCount === 0 && (
                      <ExclamationTriangleIcon className="h-5 w-5 text-warning" />
                    )}
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
      {sessions.length < 1 && (
        <div className="py-8">
          <div className="text-center">
            <h3 className="mt-2 text-md font-medium text-gray-900">
              No sessions
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              No recently completed sessions within last 7 days. <br />
            </p>
          </div>
        </div>
      )}
    </>
  );
};

function statusTagColor(str) {
  switch (str) {
    case "UPCOMING":
      return `bg-warning`;
    case "ONGOING":
      return `bg-info`;
    default:
      return `bg-base-300`;
  }
}
