import { Helmet } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import logo from "../logo.png";

export const PublicLayout = (props) => {
  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-neutral py-6 sm:py-12">
      <header>
        <Toaster />
        <Helmet>
          <title>{props.title}</title>
        </Helmet>
        <div className="pb-8">
          <img src={logo} alt="App logo" className="w-72 mx-auto" />
        </div>
      </header>
      <main>
        <div className="relative bg-white px-6 pt-10 pb-12 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
          {props.children}
        </div>
      </main>
      <footer>
        <div className="text-center p-4 text-white">
          <a
            href="https://swastikdance.com.au/privacy-policy/"
            className="text-sm font-semibold uppercase tracking-wide"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>{" "}
          •{" "}
          <a
            href="https://swastikdance.com.au/terms/"
            className="text-sm font-semibold uppercase tracking-wide cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
        </div>
      </footer>
    </div>
  );
};
