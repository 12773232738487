import moment from "moment";

export const convertUnixTimestamp = (datetimeString) => {
  // convert unix timestamp to milliseconds and then create a new Date object
  const date = new Date(datetimeString * 1);
  // Split the datetimeString into date and time parts
  const parsedDatetime = moment(date);
  const isoDateTime = parsedDatetime.format("DD-MM-YYYY hh:mm A");

  return isoDateTime;
};
