import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import Login from "./page/Login";
import Reset from "./page/Reset";
import Home from "./page/Home"; // manage student, view enrolments
import Profile from "./page/Profile"; // manage profile
import Session from "./page/Session";
import Error from "./page/Error";

function App() {
  return (
    <Routes>
      {/* open routes */}
      <Route path="*" element={<Error />} />
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* teacher routes */}
      <Route
        path="/"
        element={
          <TeacherRoute>
            <Home />
          </TeacherRoute>
        }
      />

      <Route
        path="/profile"
        element={
          <TeacherRoute>
            <Profile />
          </TeacherRoute>
        }
      />

      <Route
        path="/session/:id"
        element={
          <TeacherRoute>
            <Session />
          </TeacherRoute>
        }
      />
    </Routes>
  );
}

export default App;

function TeacherRoute(props) {
  const { user } = useContext(AuthContext);
  // teacher but not admin
  return (
    <>
      {user && user.data.teacher && !user.data.admin ? (
        props.children
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // not logged in
  return <>{!user ? props.children : <Navigate to="/" />}</>;
}
