import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { CHANGE_PASSWORD } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const ChangePassword = (props) => {
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const notifyError = () => toast.error("Unable to change password!");
  const notifySuccess = () => toast.success("Information updated!");

  const [changePassword, { loading, data, error }] =
    useMutation(CHANGE_PASSWORD);

  const onSubmit = (data) => {
    const { password } = data.change;
    changePassword({ variables: { password } });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      notifySuccess();
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* password */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">New password</span>
            </label>
            <input
              type="password"
              name="password"
              placeholder="********"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("change.password")}
              required
            />
          </div>
        </div>
        {/* confirm password */}
        <div>
          <div>
            <label className="label">
              <span className="label-text font-semibold">Confirm password</span>
            </label>
            <input
              type="password"
              name="confirmPassword"
              placeholder="********"
              className="input input-bordered w-full max-w-md bg-white"
              {...register("change.confirmPassword", {
                validate: (value) => {
                  if (value === watch("change.password")) {
                    console.log("values match");
                    return true;
                  }
                  return "Passwords do not match";
                },
              })}
              required
            />
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="change.confirmPassword"
          render={({ message }) => <p className="pt-2 text-error">{message}</p>}
        />
        <div className="pt-4">
          <button type="submit" className={loader ? "btn loading" : "btn"}>
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};
